<template>
  <standard-page :sub_title="'Process Definitions'" :title="registry.name" :definition="$DEFINITIONS.provenance.processDefinition" v-if="registry">
    <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'provenances-home'}">Provenance</router-link>
      </span>
      <span>
        <router-link :to="{ name: 'provenances-registries'}">Registries</router-link>
      </span>
      <span v-if="registry">
        Registry: {{registry.name | truncate(10, '.....')}}
      </span>
    </template>
    <template v-slot:buttons>
      <button @click="addProcessDefinition" class="d-none d-xl-block float-right btn btn-orange rounded font-weight-bold" title="New Process Definition">
        <i class="fas fa-plus-square mg-r-10" />New Process Definition
      </button>
        <button @click="addProcessDefinition" class="d-xl-none float-right w-35 btn btn-icon btn-orange rounded" title="New Process Definition">
            <i class="typcn typcn-document-add" />
        </button>
    </template>
    <template v-slot:content>
      <data-table :api="api" :columns="columns" :rename="updateProcessDefinition" :row-click="view" :server-side="true" @ready="onReady" list-key="process_definitions" table-id="process_definition_table" total-key="total">
      </data-table>
      <delete-modal :service="'provenance'" :context="'Process'" :table="table" :url="`provenance/registries/${registryid}/definitions/${processDefinitionIdToDelete}`" v-if="processDefinitionIdToDelete"></delete-modal>
    </template>
  </standard-page>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "@/event-bus";

export default {
  name: "ProcessDefinition",
  props: ['registryid'],
  computed: {
    ...mapGetters(["currentUser"])
  },
  data() {
    return {
      api: `${this.$provenance.defaults.baseURL}/provenance/registries/${this.registryid}/definitions`,
      columns: [
        { type: 'name' },
      {
          title: 'Short Name',
          data: 'nickname',
          orderable: false
      },
        { type: 'provenanceProcessDefinitionStatus' },
        { type: 'created' },
        { type: 'extrinsic' },
        {
          type: 'action',
          defaultContent: `<div class="offset-2 btn-group btn-icon-list">
                            ${this.$StandardButtons.renameButton('Rename Process Definition')}
                            ${this.$StandardButtons.deleteButton('#confirmationmodal', 'Delete Process Definition')}
                           </div>`
        }
      ],
      table: null,
      registry: null,
      processDefinitionIdToDelete: null,
    };
  },
  mounted() {
    this.getRegistry();
  },
  methods: {
    onReady(table) {
      this.table = table;
      let self = this;

      $(async function () {
        $('#process_definition_table tbody').on('click', '.delete', function () {
          let row = table.row($(this).parents('tr')).data();
          if(row.status === 'Active'){
              self.$toastr.e("Active Process Definition cant be deleted", "Error");
              return
          }
          self.setProcessDefinitionIdToDelete(row.process_definition);
          table.ajax.reload();
        });
      });
    },
    async getRegistry() {
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}`);
        this.registry = data;
      } catch (e) {
        this.$toastr.e("Error retrieving Registry", "Error");
      }
    },
    view(processDefinition) {
      this.$router.push({ name: 'process-definition-steps', params: { registryid: this.registryid, processdefinitionid: processDefinition.process_definition } });
    },
    setProcessDefinitionIdToDelete(processDefinitionId) {
      this.processDefinitionIdToDelete = processDefinitionId;
    },
    addProcessDefinition() {
      this.$router.push({ name: 'new-process-definitions', params: { registryid: this.registryid } });
    },
    async updateProcessDefinition(processDefinition, name) {
      if (processDefinition.status !== 'Active') {
        EventBus.$emit('openLoader');
        try {
          await this.$provenance.patch(`provenance/registries/${this.registryid}/definitions/${processDefinition.process_definition}`, { name });
          this.$toastr.s("Process Definition Successfully updated!", 'Success');
        } catch (e) {
          this.$toastr.e("Process Definition updating failed", 'Error');
        } finally {
          EventBus.$emit('closeLoader');
        }
      } else {
        this.$toastr.e("Can't edit a active Process", "Error");
      }
    }
  }
}
</script>

<style scoped>
</style>